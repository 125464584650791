import Vue from "vue";
import jquery from "jquery";
import moment from "moment-timezone";

export const mixin = {
  data() {
    return {
      notifications: {
        total: 0,
        users: 0,
      },
    };
  },
  methods: {
    $$: jquery,
    getUser() {
      return new Promise((resolve, reject) => {
        var userSend = JSON.parse(sessionStorage.getItem("user"));
        resolve(userSend);
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getStatusList(){
      return [
        {
          name:"Trip started",
          color:"#B4C8E6",
          value:"tripStarted"
        },
        {
          name:"Loading",
          color:"#006EBE",
          value:"loadStarted"
        },
        {
          name:"Exiting plant",
          color:"#325596",
          value:"loadEnded"
        },
        {
          name:"Traveling to jobsite",
          color:"#6E32A0",
          value:"loadingLocationLeft"
        },
        {
          name:"Waiting to unload",
          color:"#FFFF00",
          value:"unloadingLocationEntered"
        },
        {
          name:"Unloading",
          color:"#FFD764",
          value:"unloadStarted"
        },
        {
          name:"Waiting for signature",
          color:"#FFD764",
          value:"unloadEnded"
        },
        {
          name:"Delivery accepted",
          color:"#00AF50",
          value:"deliveryAccepted"
        },
        {
          name:"Returning to plant",
          color:"#9B55CD",
          value:"unloadingLocationLeft"
        },
        {
          name:"Arrived to plant",
          color:"#B4C8E6",
          value:"loadingLocationEntered"
        },
        {
          name:"Skip signature",
          color:"#00AF50",
          value:"skipSignature"
        }
      ]
    },
    getEventFromEventName(eventName) {
      switch (eventName) {
        case "tripStarted":
        return "Trip started";
        case "loadStarted":
        return "Loading";
        case "loadEnded":
        return "Exiting plant";
        case "loadingLocationLeft":
        return "Traveling to jobsite";
        case "unloadingLocationEntered":
        return "Waiting to unload";
        case "unloadStarted":
        return "Unloading";
        case "unloadEnded":
        return "Waiting for signature";
        case "skipSignature":
        return "Waiting for signature";
        case "deliveryAccepted":
        return "Delivery accepted";
        case "unloadingLocationLeft":
        return "Returning to plant";
        case "loadingLocationEntered":
        return "Arrived to plant";
      }
    },
    getColorFromEventName(eventName) {
      
      switch (eventName) {
        case "tripStarted":
        return "#B4C8E6";
        case "loadStarted":
        return "#006EBE";
        case "loadEnded":
        return "#325596";
        case "loadingLocationLeft":
        return "#6E32A0";
        case "unloadingLocationEntered":
        return "#FFFF00";
        case "unloadStarted":
        return "#FFD764";
        case "unloadEnded":
        return "#FFD764";
        case "skipSignature":
        return "#00AF50";
        case "deliveryAccepted":
        return "#00AF50";
        case "unloadingLocationLeft":
        return "#9B55CD";
        case "loadingLocationEntered":
        return "#B4C8E6";
      }
    },
    isDeliveryActive(lastEvent, lastEventTimestamp){
      var inactiveEvents = ['skipSignature','deliveryAccepted','unloadingLocationLeft','loadingLocationEntered']
      if(inactiveEvents.includes(lastEvent) && moment().diff(moment(lastEventTimestamp), 'hours') > 24){
        return false
      }
      return true
    },
    isTruckActive(lastEventTimestamp){
      if(moment().diff(moment(lastEventTimestamp), 'hours') > 24){
        return false
      }
      return true
    },
    getTextColor(hexColor) {
      // Convert hex to RGB
      let r = parseInt(hexColor.slice(1, 3), 16);
      let g = parseInt(hexColor.slice(3, 5), 16);
      let b = parseInt(hexColor.slice(5, 7), 16);
      
      // Calculate relative luminance (https://www.w3.org/TR/WCAG20/#relativeluminancedef)
      let luminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);
      
      // Use a threshold to determine if the text should be black or white
      return luminance > 0.5 ? "#000000" : "#FFFFFF";
    },
    convertDistanceToString(meters, system){
      let uom = "";
      meters = parseFloat(meters);
      switch(system){
        case "IMPERIAL":
          meters = meters * 0.000621371;
          uom = "MI";
          break; 
          case "METRIC":
          default:
          meters = meters * 0.001;
          uom = "KM";
          break;
      }
      return meters.toFixed(2) + "\u00A0" + uom;
    }
  }
}

Vue.mixin(mixin);