const dotenv = require('dotenv');
var path = require('path');
// dotenv.config();
// dotenv.config({ path: path.resolve(__dirname, '../openshift/rcs-cicd/rcs-cloud.env') });
// dotenv.config({ path: path.resolve(__dirname, '../openshift/rcs-cicd/rcs-cloud.secrets') });
dotenv.config({ path: path.resolve(__dirname, '../conf/properties.env') });



module.exports = {

	// Node Config

	node: {

		port: process.env.OPENSHIFT_NODEJS_PORT || 9090,
		host: process.env.OPENSHIFT_NODEJS_IP || '0.0.0.0'

	},

	// JWT Config
	jwt: {

		jwtSecret: process.env.JWT_SECRET || '@mura2020',
		jwtExpired: process.env.JWT_EXPIRED || '1d'

	},

	// API config	
	api: {

		API_INTEGRATION: process.env.API_INTEGRATION || 'http://td-integration-truckdashboard-dev.apps.ocp.dev.lafargeholcim-go.com' 

	},

	general: {
		version: process.env.VERSION || '0',
		enviroment: process.env.ENVIROMENT || ''
	}

}


