<template>
<div>
  <transition name="fade" mode="out-in">
    <router-view :key="$route.fullPath"/>
  </transition>
   <notifications group="app" />

</div>

</template>

<script>
export default {
  name: 'App'
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';

  @import '~primevue/resources/themes/saga-blue/theme.css';      //theme
  @import '~primevue/resources/primevue.min.css';                //shared css
  @import '~primeicons/primeicons.css';     
  @import "~primeflex/primeflex.css";

</style>
