import axios from 'axios'
import Vue from 'vue'
import { store } from "../store/store";

const _moment = require("moment");
const _momentRange = require("moment-range");
const moment = _momentRange.extendMoment(_moment);

const { api } = require('../../config')

 var baseURL ='/';
let axiosBase = axios.create({ baseURL });


axiosBase.interceptors.request.use(function (config) {
    const session = sessionStorage.getItem("user")

    if (session != null) {
        const user = JSON.parse(session)
        // console.log(user)
        // TODO: Hasta que no este hecho el login se usara este token para hacer las llamadas
        //config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTUyMTc1NTZ9.7LnKBBultNvqu3VXoOb-f-UftYQ5tSEb7bBvnaogKVk`;
        config.headers.Authorization = `Bearer ${user.token}`;
        config.headers.selectedplant = store.state.selectedPlant;
        config.headers.timestamp = moment().format("YYYY-MM-DDTHH:mm:ss");
    }

    return config
}, function (err) {
    return Promise.reject(err)
})

axiosBase.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (403 === error.response.status) {
        sessionStorage.clear();
        window.location = window.location.pathname;
    } else {
        return Promise.reject(error);
    }
});

export default {
    install() {
        Vue.prototype.$http = axiosBase
    }
}