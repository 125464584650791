require('babel-polyfill')

import Vue from 'vue'

import CoreuiVue from '@coreui/vue';
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import ToastService from 'primevue/toastservice';
import {store } from './store/store'
Vue.use(ToastService);
Vue.use(CoreuiVue)
import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);

import _http from './plugins/http'
import version from '../config'

export const bus = new Vue();

const dotenv = require('dotenv');
dotenv.config();

import VuejsDialog from 'vuejs-dialog';
import './assets/css/vuejs-dialog.min.css';
let params = new URLSearchParams(location.search);
var themeUrl = params.get('theme');

import types from "./ThemeJs/theme";
var themeUrlName;
switch (themeUrl) {
  case '1':
    themeUrlName = 'holcimRedAlternative';
    break;
  case '2':
    themeUrlName = 'laFargeGreenAlternative';
    break;
  case '3':
    themeUrlName = 'laFargeHolcimblueAlternative';
    break;

  default:
    themeUrlName = 'laFargeHolcimblueAlternative';
    break;
}


if (localStorage.theme === undefined || localStorage.theme === null) {
  // console.log("local dentro del if")
  localStorage.theme = themeUrlName;
  types['types'].theme.map((theme) => {
    if (theme.name == themeUrl) {
      theme.state = true;

    } else {
      theme.state = false;

    }
  })
  let tema = types.types.theme.filter(themeElement => themeElement.name == themeUrl);
  // console.log(tema, "tema");
  types.selectTheme(tema);
} else {
  types['types'].theme.map((theme) => {
    if (theme.name == localStorage.theme) {
      theme.state = true;

    } else {
      theme.state = false;

    }
  })
  let tema = types.types.theme.filter(themeElement => themeElement.name == localStorage.theme);
  // console.log(tema, "tema");
  types.selectTheme(tema);
}


Vue.use(VuejsDialog);
Vue.prototype.$version = version.general.version;

import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';

var i18n;
Vue.use(VueI18Next);

if (sessionStorage.getItem("i18n") == null || sessionStorage.getItem("i18n") == "") {
  // console.log("main dentro if i18n");

  i18next.init({
    lng: 'en',
    fallbackLng: 'en',
    // debug: true, // Activar cuando se quiera depurar el modulo i18
    resources: {
      en: {

        loading: i18next.t('loading'),
        login: i18next.t('login'),
        password: i18next.t('password'),
        userName: i18next.t('username'),
        userNamePasswordIncorrect: i18next.t('Username or password are incorrect'),

      }
    }
  });
  i18n = new VueI18Next(i18next);
  let element = {

    "login": i18n.i18next.t('login'),
    "userName": i18n.i18next.t('username'),
    "password": i18n.i18next.t('password'),
    "userNamePasswordIncorrect": i18n.i18next.t('userNamePasswordIncorrect'),
    "loading": i18n.i18next.t('loading'),

  }
  i18n.i18next.addResourceBundle("en", "translation", element);
  sessionStorage.i18n = "";

} else {
  let idiomasI18n = JSON.parse(sessionStorage.getItem("i18n"));

  i18next.init({
    lng: 'en',
    fallbackLng: idiomasI18n.fallBackLanguages,
    debug: true,
    resources: {
      en: {
        login: {
          loading: i18next.t('loading'),
          login: i18next.t('login'),
          password: i18next.t('password'),
          userName: i18next.t('username'),
          userNamePasswordIncorrect: i18next.t('Username or password are incorrect'),
        }
      }
    }
  });
  i18n = new VueI18Next(i18next);


  for (const key in idiomasI18n.idiomas) {
    const element = idiomasI18n.idiomas[key];
    i18n.i18next.addResourceBundle(key, "translation", element);
  }
  i18n.i18next.changeLanguage(idiomasI18n.idiomaDefecto)
}
export default i18n;




// TODO: configuracion common functions
import './plugins/commons'

// console.log("despues exportssssssssssssssssss")


// TODO: configuracion de axios
Vue.use(_http)


Vue.prototype.$error = function (data) {


  if ((data.ret == '403') || (data.ret == '401')) {


    let msg = '';
    if (data.ret == '403') msg = i18n.i18next.t('expired_sesion_message');
    if (data.ret == '401') msg = i18n.i18next.t('access_denied_message') + '(' + data.msg + ') ';

    this.$dialog
      .alert(msg)
      .then(function (dialog) {
        sessionStorage.removeItem("user");
        location.href = '/#/login';
      })
      .catch(function () { });

    sessionStorage.removeItem("user");


  } else {

    let msg = '';
    try { msg = data.msg.msg; } catch (e) { msg = data.toString(); }
    if (msg === undefined) msg = data.msg;


    let error = msg;

    msg = i18n.i18next.t('unavailable_service_message') + ` <br/> <a href="#" data-msg="${escape(error)}" onclick="showError(this);">${i18n.i18next.t('close')}</a>`;



    Vue.notify({
      group: 'app',
      title: i18n.i18next.t('error'),
      type: 'error',
      text: msg,
      duration: -1
    });


  }

}



import VueLogger from 'vuejs-logger';
const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

import BlockUI from 'vue-blockui'

Vue.use(BlockUI)
Vue.use(VueLogger, options);

Vue.config.productionTip = false;


import Notifications from 'vue-notification'
Vue.use(Notifications)

let SesionIddle = 1000 * 60 * 30;
const eventsHub = new Vue()

import IdleVue from 'idle-vue'
Vue.use(IdleVue, {
  idleTime: SesionIddle,
  eventEmitter: eventsHub
})

Vue.config.errorHandler = function (err, vm, info) {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`);
}



new Vue({
  el: '#app',
  router,
  i18n: i18n,
  icons,
  store:store,
  template: '<App/>',
  components: {
    App
  },

  created: function () {
  },

  // onIdle() {
  //   var user = sessionStorage.getItem("user");

  //   if (user != null) {
  //     sessionStorage.removeItem("user");
  //     //this.$router.push('login');
  //     location.href = '#/login'//?t' + (new Date()).getMilliseconds();
  //   }


  // },

  onActive() { }
})

