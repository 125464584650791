import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const Login = () => import('@/views/Login')

// ViewsCreateServiceOrder
const Dashboard = () => import('@/views/Dashboard')
const ExtDashboard = () => import('@/views/ExtDashboard')
const TruckDashboard = () => import('@/views/TruckDashboard')
const MobileDashboard = () => import('@/views/MobileDashboard')




Vue.use(Router)

function configRoutes() {
  return [
    { meta: { type: 'public' }, path: '/login', name: 'login', component: Login },
    { meta: { type: 'public' }, path: '/ext', name: 'Ext', component: ExtDashboard },
    { meta: { type: 'public' }, path: '/trck', name: 'Truck', component: TruckDashboard },
    { meta: { type: 'public' }, path: '/m', name: 'Mobile', component: MobileDashboard },
    {
      meta: { type: 'public' }, path: '/', redirect: '/dashboard', name: 'Home', component: TheContainer,


      children: [
        { meta: { type: 'public' }, path: 'dashboard', name: 'Dashboard', component: Dashboard },
      ],
      
    }
  ]
}




let router = new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  saveScrollPosition: true,
  history: true,
  force: true,
  routes: configRoutes()
})



router.beforeEach((to, from, next) => {
  let user = sessionStorage.getItem("user");
 
  var isAuthenticated = false;
  try {
    isAuthenticated = (user !== undefined && user !== null);

  } catch (e) { console.log(e) }

  if(to.query.filters){
    sessionStorage.setItem("user", JSON.stringify({token: to.query.filters}));
    isAuthenticated=true;
  }else if(to.query.t){
    sessionStorage.setItem("user", JSON.stringify({token: to.query.t}));
    isAuthenticated=true;
  }

  if (to.path != '/ConfigFile' && to.path != '/login') {
    if (Vue.prototype.ConfigFile) {
      console.log("configFile");
      next('/ConfigFile')
    } else if (!isAuthenticated) {

      next('/login')


    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
