import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Create a new store instance.
export const store = new Vuex.Store({
    state: {

        trucks: [],
        markers:[],
        centerMap:false,
        availablePlants:[],
        filterType:null,
        selectedPlant:null,
        plantLocations:[],
        selectedPlants: [],
        totalTrucks: 0,
        truckPages: 0,
        truckActivePages: 0,
        filtering: false,
        trucksEvent: false,
        dnEvent: false,
        filters: {
            trucks: [],
            plants: [],
            deliveryNotes: [],
            shiptos: [],
            soldtos: [],
            carriers: [],
            forwardingagents: [],

            deliveryNoteStatus: ['all'],
            showInactive:false,
            showPlants:false
        },
        dictionaries: {
            shiptos: [],
            soldtos: [],
            carriers: [],
            forwardingagents: [],
        }

    },
    mutations: {
        addTruck(state, truck) {
            // console.log('websocket truck',truck) 
            truck.mode = 'truck';
            var existingTruck = state.trucks.find(x => x.truck_plate == truck.truck_plate && x.mode == 'truck');
            state.trucks = state.trucks.filter(x => x.truck_plate != truck.truck_plate || x.mode != truck.mode );
            truck.checked = existingTruck ? existingTruck.checked : false;        
            existingTruck.show ? truck.show = true : truck.show = false;
            state.trucks.push(truck);
        }, 
        setTotalTrucks(state, info) {
            // state.totalTrucks = info.trucks;
            state.truckPages = Math.ceil(info.trucks / info.perPage)
        },
        setFiltering(state, value) {
            state.filtering = value;
        },
        setTrucks(state, trucks) {
            state.trucks = state.trucks.filter(x => x.checked)
            state.trucks.forEach(truck => {
                truck.show = false;
            })
            trucks.forEach(truck => {
                if (!(state.filters.showInactive)) {
                    state.truckActivePages = Math.ceil(truck.total_trucks/10)
                }
                if (state.totalTrucks != truck.total_trucks) {
                    state.totalTrucks = truck.total_trucks
                } 

                truck.mode = 'truck';
                truck.show = true;
            var existingTruck = state.trucks.find(x => x.truck_plate == truck.truck_plate && x.mode == 'truck');
            state.trucks = state.trucks.filter(x => x.truck_plate != truck.truck_plate || x.mode != truck.mode );
            truck.checked = existingTruck ? existingTruck.checked : false;
            state.trucks.push(truck);
            })
            state.trucks = state.trucks.filter(x => x.mode == 'truck')
            
        },
        setSelectedPlants(state, plants) {
        
            if (plants !== null && plants !== undefined) {
                // Convertir plants a array si no lo es
                if (!Array.isArray(plants)) {
                    plants = [plants];
                }
        
                // Eliminar elementos que no están en plants
                state.selectedPlants = state.selectedPlants.filter(plant => plants.includes(plant));
        
                // Añadir nuevos elementos sin duplicados
                plants.forEach(plant => {
                    if (!state.selectedPlants.some(e => e === plant)) {
                        state.selectedPlants = [...state.selectedPlants, plant];
                    }
                });
            }
        },        
        checkTrucksPlant(state, plants) {
            if (plants.length > 0) {
                state.trucks = state.trucks.filter(t => plants.includes(t.plant))
            }
        },
        setTrucksDn(state, trucks) {
    //            if (trucks) {
    //    const truckPlates = trucks.map(truck => truck.truck_plate);
    //    state.trucks = state.trucks.filter(truck => truckPlates.includes(truck.truck_plate));
    //}
       state.trucks = state.trucks.filter(x => x.checked)
            state.trucks.forEach(truck => {
                truck.show = false;
            })
            if (trucks) {
                trucks.forEach(truck => {
                    // comprobar si esta inactivo y guardar ese valor en una variable
                    if (!(state.filters.showInactive)) {
                        state.truckActivePages = Math.ceil(truck.total_deliveries/10)
                    }
                    if (state.totalTrucks != truck.total_deliveries) {
                        state.totalTrucks = truck.total_deliveries
                    } 
                    truck.mode = 'dn';
                   
                    truck.show = true;
                    var existingTruck = state.trucks.find(x => x.truck_plate == truck.truck_plate && x.mode == 'dn');
                    state.trucks = state.trucks.filter(x => x.truck_plate != truck.truck_plate || x.mode != truck.mode);
                    truck.checked = existingTruck ? existingTruck.checked : false;
                    state.trucks.push(truck);
                })
            }
            state.trucks = state.trucks.filter(x => x.mode == 'dn')
        },
         addTruckDN(state, truck) {
            // console.log('websocket truck',truck)
            truck.mode = 'dn';
            var existingTruck = state.trucks.find(x => x.truck_plate == truck.truck_plate && x.mode == 'dn');
            if (existingTruck) {
                truck.checked = existingTruck ? existingTruck.checked : false;
                existingTruck.show ? truck.show = true : truck.show = false;
            }
            state.trucks = state.trucks.filter(x => x.truck_plate != truck.truck_plate || x.mode != truck.mode);
            state.trucks.push(truck);
        },
        setMarker(state, marker){
            state.markers = state.markers.filter(x => x.truck_plate != marker.getData().truck_plate);
            state.markers.push(marker.getData());
        },
        recalcTrucks(state) {
            var trucksFilter = state.filters.trucks;
            var plantsFilter = state.filters.plants;
            var deliveryNotesFilter = state.filters.deliveryNotes;
            var shiptosFilter = state.filters.shiptos;
            var soldtosFilter = state.filters.soldtos;
            var carriersFilter = state.filters.carriers;
            var forwardingagentsFilter = state.filters.forwardingagents;

            for (var truck of state.trucks) {
                if (
                    !trucksFilter.includes(truck.truck_plate)
                    &&
                    !plantsFilter.includes(truck.plant)
                    &&
                    !deliveryNotesFilter.includes(truck.dn_number)
                    &&
                    !shiptosFilter.includes(truck.ship_to)
                    &&
                    !soldtosFilter.includes(truck.sold_to)
                    &&
                    !carriersFilter.includes(truck.carrier)
                    &&
                    !forwardingagentsFilter.includes(truck.forwarding_agent)
                ) {
                    truck.toDelete = true;
                }

            }

            state.trucks = state.trucks.filter(x => !x.toDelete);
        },
        addFilter(state, filter) {
            var type = filter.type;
            var value = filter.value;

            if (!Array.isArray(value)) {
                state.filters[type] = state.filters[type].filter(x => x != value);
                state.filters[type].push(value);
            } else {
                state.filters[type] = value
            }

        },
        showInactive(state, show) {
            state.filters.showInactive = show;
        },
        showPlants(state, show) {
            state.filters.showPlants = show;
        },
        removeFilter(state, filter) {
            var type = filter.type;
            var value = filter.value;

            state.filters[type] = state.filters[type].filter(x => x != value);
        },
        addToDictionary(state, filter) {
            var type = filter.type;
            var value = filter.value;
            console.log('addToDictionary', filter)
            state.dictionaries[type] = state.dictionaries[type].filter(x => x.key != value.key);
            state.dictionaries[type].push(value);

        },
        reset(state){
            state.trucks=[]
            state.markers=[]
        },
        untriggerAll(state) {
            state.trucks = state.trucks.filter(x => x.show)
            state.trucks.forEach(truck => {
                truck.checked = false;
            })
            state.markers=[]
        },
        trigger(state){
            state.trucks = state.trucks.filter(x=>x)
            state.markers = state.markers.filter(x=>x)
            state.centerMap = true
        },
        resetCenterMap(state){
            state.centerMap = false
        },
        availablePlants(state, availablePlants){
            state.availablePlants = availablePlants;
        },
        filterType(state, filterType){
            state.filterType = filterType
        },
        selectedPlant(state, selectedPlant){
            state.selectedPlant = selectedPlant
        },
        triggerTruck(state, obj){
            var truck_plate = obj.truck_plate;
            var checked = obj.checked;

            var selectedTrucks= state.trucks.filter(x => x.truck_plate == truck_plate);
            state.trucks= state.trucks.filter(x => x.truck_plate != truck_plate);
            selectedTrucks.forEach(x=> {
                if(x.truck_plate == truck_plate){
                    x.checked = checked
                }
                state.trucks.push(x)
            })

            //.filter(x => x.truck_plate != truck.truck_plate || x.mode != truck.mode);
        },
        triggerTruckAll(state, payload){
            const checked = payload.checked;
            const page = payload.currentPage;
            const pageSize = 10;
            const start = (page - 1) * pageSize;
            const end = start + pageSize;
            const mode = payload.mode;
            const selectedTrucks = payload.trucks ? payload.trucks : state.trucks;
            
             const filteredTrucks = selectedTrucks.map((truck, index) => {
                const modifiedTruck = { ...truck, mode: mode };
                if (checked) {
                    modifiedTruck.checked = checked;
                    modifiedTruck.show = index >= start && index < end;
                } else {
                    modifiedTruck.checked = false;
                }
                return modifiedTruck;
            });
                state.trucks = filteredTrucks

        },
        setPlants(state, plants){
            state.plantLocations = plants
        }

    }
})