
//SET COLOR THEME
var color = getComputedStyle(document.documentElement).getPropertyValue('--primary');
// console.log(color);
var types = {
    theme: [

        {
            name: "holcimRedAlternative",
            state: false,
            logoLarge: "img/holcimLargeApp_white.png",
            logoLargeLogin: "img/holcimLarge_white.png",
            logoSquare: "img/holcimSquare_white.png",
            logoLargeDark: "img/holcimLarge_dark.png",
            logoSquareDark: "img/holcimSquare_dark.png",
            colors: {
                primary: '#DD2E00',
                primaryDark: '#BE0000',
                primary50: '#F4C2C2',
                secondaryCustom: '#BABABA',
                variableBlanca: '#BABABA',
                primaryDarkTheme: '#A01212',
                primaryDarkThemeDark: '#7E1111',
                fontColorHeader: '#ffffff',
                fontColorCardHeader: '#555555',
                fontColorCardHeaderAccordeon: '#ffffff',
                colorBackgroundHeader: '#DD2E00',
                iconColor: '#FFFFFF',
                linkColor: '#FFFFFF',
                fontColorSelect: '#777777',
                colorBackgroundSide: '#2c2c34',
                colorBackgroundSideToggle: '#00000033',
                colorBackgroundSideHover: '#BABDC1',
                colorBackgroundSideBoton: '#00000033',
                colorBackgroundSideLogo: '#00000033',
                widgetBlock: '#d00000',
                widgetConfirmed: '#F06013',
                widgetOffLine: '#959595',
                widgetdelivery_dashboard_1: '#B404AE',
                widgetRequested: '#024B85',
                widgetBlockDark: '#B60202',
                widgetConfirmedDark: '#D25716',
                widgetOffLineDark: '#6D6D6D',
                widgetdelivery_dashboard_1Dark: '#6A0888',
                widgetRequestedDark: '#013761',
                breadcrumb: '#3f3f3f',
                iconoToggle: 'assets/icons/bars_white.png',
                glow: '#a6262680',
                badgeDanger: '#0f7ae0',
                blancoGris: '#efefef',
                confirmedButton: '#dfd223',
                releaseButton: '#04a835',
                confirmedButtonDark: '#afa51b',
                releaseButtonDark: '#027c27',
                cancelButton: '#8600d9',
                cancelButtonDark: '#5a048f',
                completeButton: '#355be7',
                completeButtonDark: '#2947b3',
                schedulerStatus0: '#CFCFCF',
                schedulerStatus7: '#FA7200',
                schedulerStatus2: '#F71ED0',
                schedulerStatus3: '#d00000',
                schedulerStatus4: '#FFE633',
                schedulerStatus5: '#F11815',
                schedulerStatus6: '#A1F115',
                schedulerStatus8: '#017C60',
                accordeon_highlight: '#FD9190'


            }
        },
        {
            name: "laFargeGreenAlternative",
            state: false,
            logoLarge: "img/holcim_new.png",
            logoLargeLogin: "img/holcim_new.png",
            logoSquare: "img/holcim_new_square.png",
            logoLargeDark: "img/holcim_new_dark.png",
            logoSquareDark: "img/holcim_new_dark_square.png",
            colors: {
                primary: '#1d4370',
                primaryDark: '#112945',
                primary50: '#3a86e0',
                secondaryCustom: '#BABABA',
                variableBlanca: '#BABABA',
                primaryDarkTheme: '#016641',
                primaryDarkThemeDark: '#014B30',
                fontColorHeader: '#ffffff',
                fontColorCardHeader: '#ffffff',
                fontColorCardHeaderAccordeon: '#ffffff',
                colorBackgroundHeader: '#1d4370',
                iconColor: '#FFFFFF',
                linkColor: '#FFFFFF',
                fontColorSelect: '#777777',
                colorBackgroundSide: '#2c2c34',
                colorBackgroundSideToggle: '#00000033',
                colorBackgroundSideHover: '#BABDC1',
                colorBackgroundSideBoton: '#00000033',
                colorBackgroundSideLogo: '#00000033',
                widgetBlock: '#d00000',
                widgetConfirmed: '#F06013',
                widgetOffLine: '#959595',
                widgetRequested: '#024B85',
                widgetBlockDark: '#B60202',
                widgetConfirmedDark: '#D25716',
                widgetOffLineDark: '#6D6D6D',
                widgetRequestedDark: '#013761',
                widgetdelivery_dashboard_1: '#B404AE',
                widgetdelivery_dashboard_1Dark: '#6A0888',
                breadcrumb: '#3f3f3f',
                iconoToggle: 'assets/icons/bars_white.png',
                glow: '#1d437080',
                badgeDanger: '#e55353',
                blancoGris: '#BABABA',
                confirmedButton: '#dfd223',
                releaseButton: '#04a835',
                confirmedButtonDark: '#afa51b',
                releaseButtonDark: '#027c27',
                cancelButton: '#8600d9',
                cancelButtonDark: '#5a048f',
                completeButton: '#355be7',
                completeButtonDark: '#2947b3',
                schedulerStatus0: '#CFCFCF',
                schedulerStatus7: '#FA7200',
                schedulerStatus2: '#F71ED0',
                schedulerStatus3: '#d00000',
                schedulerStatus4: '#FFE633',
                schedulerStatus5: '#F11815',
                schedulerStatus6: '#A1F115',
                schedulerStatus8: '#017C60',
                accordeon_highlight: '#FD9190'

            }
        },
        {
            name: "laFargeHolcimblueAlternative",
            state: true,
            logoLarge: "img/laFargeHolcimLargeApp_transparent.png",
            logoLargeLogin: "img/laFargeHolcimLarge_transparent.png",
            logoSquare: "img/laFargeHolcimSquare_transparent.png",
            logoLargeDark: "img/logo_holcim_negativo.png",
            logoSquareDark: "img/logo_holcim_negativo2.png",
            colors: {
                primary: '#0073AF',
                primaryDark: '#006294',
                primary50: '#A8D3EB',
                secondaryCustom: '#BABABA',
                variableBlanca: '#BABABA',
                primaryDarkTheme: '#014466',
                primaryDarkThemeDark: '#00344E',
                fontColorHeader: '#ffffff',
                fontColorCardHeader: '#ffffff',
                fontColorCardHeaderAccordeon: '#ffffff',
                colorBackgroundHeader: '#0073AF',
                iconColor: '#FFFFFF',
                linkColor: '#FFFFFF',
                fontColorSelect: '#777777',
                colorBackgroundSide: '#2c2c34',
                colorBackgroundSideToggle: '#00000033',
                colorBackgroundSideHover: '#BABDC1',
                colorBackgroundSideBoton: '#00000033',
                colorBackgroundSideLogo: '#00000033',
                widgetBlock: '#d00000',
                widgetConfirmed: '#F06013',
                widgetOffLine: '#959595',
                widgetRequested: '#024B85',
                widgetBlockDark: '#B60202',
                widgetConfirmedDark: '#D25716',
                widgetOffLineDark: '#6D6D6D',
                widgetRequestedDark: '#013761',
                widgetdelivery_dashboard_1: '#B404AE',
                widgetdelivery_dashboard_1Dark: '#6A0888',
                breadcrumb: '#3f3f3f',
                iconoToggle: 'assets/icons/bars_white.png',
                glow: '#2661a680',
                badgeDanger: '#e55353',
                blancoGris: '#BABABA',
                confirmedButton: '#dfd223',
                releaseButton: '#04a835',
                confirmedButtonDark: '#afa51b',
                releaseButtonDark: '#027c27',
                cancelButton: '#8600d9',
                cancelButtonDark: '#5a048f',
                completeButton: '#355be7',
                completeButtonDark: '#2947b3',
                schedulerStatus0: '#CFCFCF',
                schedulerStatus7: '#FA7200',
                schedulerStatus2: '#F71ED0',
                schedulerStatus3: '#d00000',
                schedulerStatus4: '#FFE633',
                schedulerStatus5: '#F11815',
                schedulerStatus6: '#A1F115',
                schedulerStatus8: '#017C60',
                accordeon_highlight: '#FD9190'
            }
        }
    ]
}
function selectTheme(array) {
    array.map((tema) => {

        if (tema.name == "holcimRedAlternative" && tema.state == true) {
            document.documentElement.style.removeProperty('--primary');
            document.documentElement.style.setProperty('--primary', tema.colors.primary);
            document.documentElement.style.setProperty('--primary-dark', tema.colors.primaryDark);
            document.documentElement.style.setProperty('--primary-50', tema.colors.primary50);
            document.documentElement.style.setProperty('--primary-dark-theme', tema.colors.primaryDarkTheme);
            document.documentElement.style.setProperty('--primary-dark-theme-dark', tema.colors.primaryDarkThemeDark);
            document.documentElement.style.setProperty('--font-color-header', tema.colors.fontColorHeader);
            document.documentElement.style.setProperty('--font-color-card-header', tema.colors.fontColorCardHeader);
            document.documentElement.style.setProperty('--font-color-card-header-accordeon', tema.colors.fontColorCardHeaderAccordeon);
            document.documentElement.style.setProperty('--color-background-header', tema.colors.colorBackgroundHeader);
            document.documentElement.style.setProperty('--icon-color', tema.colors.iconColor);
            document.documentElement.style.setProperty('--link-color', tema.colors.linkColor);
            document.documentElement.style.setProperty('--font-color-select', tema.colors.fontColorSelect);
            document.documentElement.style.setProperty('--background-color-side', tema.colors.colorBackgroundSide);
            document.documentElement.style.setProperty('--background-color-side-toggle', tema.colors.colorBackgroundSideToggle);
            document.documentElement.style.setProperty('--background-color-side-hover', tema.colors.colorBackgroundSideHover);
            document.documentElement.style.setProperty('--background-color-side-botton', tema.colors.colorBackgroundSideBoton);
            document.documentElement.style.setProperty('--secondary-custom', tema.colors.secondaryCustom);
            document.documentElement.style.setProperty('--varibale-blanca', tema.colors.variableBlanca);
            document.documentElement.style.setProperty('--blanco-gris', tema.colors.blancoGris);
            document.documentElement.style.setProperty('--widgetblock', tema.colors.widgetBlock);
            document.documentElement.style.setProperty('--widgetoffline', tema.colors.widgetOffLine);
            document.documentElement.style.setProperty('--widgetconfirmed', tema.colors.widgetConfirmed);
            document.documentElement.style.setProperty('--widgetrequested', tema.colors.widgetRequested);
            document.documentElement.style.setProperty('--widgetblock-dark', tema.colors.widgetBlockDark);
            document.documentElement.style.setProperty('--widgetoffline-dark', tema.colors.widgetOffLineDark);
            document.documentElement.style.setProperty('--widgetconfirmed-dark', tema.colors.widgetConfirmedDark);
            document.documentElement.style.setProperty('--widgetrequested-dark', tema.colors.widgetRequestedDark);
            document.documentElement.style.setProperty('--widgetdelivery_dashboard_1', tema.colors.widgetdelivery_dashboard_1);
            document.documentElement.style.setProperty('--widgetdelivery_dashboard_1-dark', tema.colors.widgetdelivery_dashboard_1Dark);
            document.documentElement.style.setProperty('--breadcrumb', tema.colors.breadcrumb);
            document.documentElement.style.setProperty('--iconotoggle', tema.colors.iconoToggle);
            document.documentElement.style.setProperty('--glow', tema.colors.glow);
            document.documentElement.style.setProperty('--badge-danger', tema.colors.badgeDanger);
            document.documentElement.style.setProperty('--background-color-side-log', tema.colors.colorBackgroundSideLogo);
            document.documentElement.style.setProperty('--confirmed-button', tema.colors.confirmedButton);
            document.documentElement.style.setProperty('--confirmed-button-dark', tema.colors.confirmedButtonDark);
            document.documentElement.style.setProperty('--release-button', tema.colors.releaseButton);
            document.documentElement.style.setProperty('--release-button-dark', tema.colors.releaseButtonDark);
            document.documentElement.style.setProperty('--cancel-button-dark', tema.colors.cancelButtonDark);
            document.documentElement.style.setProperty('--cancel-button', tema.colors.cancelButton);
            document.documentElement.style.setProperty('--complete-button-dark', tema.colors.completeButtonDark);
            document.documentElement.style.setProperty('--complete-button', tema.colors.completeButton);
            document.documentElement.style.setProperty('--scheduler-status-0', tema.colors.schedulerStatus0);
            document.documentElement.style.setProperty('--scheduler-status-1', tema.colors.schedulerStatus1);
            document.documentElement.style.setProperty('--scheduler-status-2', tema.colors.schedulerStatus2);
            document.documentElement.style.setProperty('--scheduler-status-3', tema.colors.schedulerStatus3);
            document.documentElement.style.setProperty('--scheduler-status-4', tema.colors.schedulerStatus4);
            document.documentElement.style.setProperty('--scheduler-status-5', tema.colors.schedulerStatus5);
            document.documentElement.style.setProperty('--scheduler-status-6', tema.colors.schedulerStatus6);
            document.documentElement.style.setProperty('--scheduler-status-7', tema.colors.schedulerStatus7);
            document.documentElement.style.setProperty('--scheduler-status-8', tema.colors.schedulerStatus8);


        }
        if (tema.name == "laFargeGreenAlternative" && tema.state == true) {
            document.documentElement.style.removeProperty('--primary');
            document.documentElement.style.setProperty('--primary', tema.colors.primary);
            document.documentElement.style.setProperty('--primary-dark', tema.colors.primaryDark);
            document.documentElement.style.setProperty('--primary-50', tema.colors.primary50);
            document.documentElement.style.setProperty('--primary-dark-theme', tema.colors.primaryDarkTheme);
            document.documentElement.style.setProperty('--primary-dark-theme-dark', tema.colors.primaryDarkThemeDark);
            document.documentElement.style.setProperty('--font-color-header', tema.colors.fontColorHeader);
            document.documentElement.style.setProperty('--font-color-card-header', tema.colors.fontColorCardHeader);
            document.documentElement.style.setProperty('--font-color-card-header-accordeon', tema.colors.fontColorCardHeaderAccordeon);
            document.documentElement.style.setProperty('--color-background-header', tema.colors.colorBackgroundHeader);
            document.documentElement.style.setProperty('--icon-color', tema.colors.iconColor);
            document.documentElement.style.setProperty('--link-color', tema.colors.linkColor);
            document.documentElement.style.setProperty('--font-color-select', tema.colors.fontColorSelect);
            document.documentElement.style.setProperty('--background-color-side', tema.colors.colorBackgroundSide);
            document.documentElement.style.setProperty('--background-color-side-toggle', tema.colors.colorBackgroundSideToggle);
            document.documentElement.style.setProperty('--background-color-side-hover', tema.colors.colorBackgroundSideHover);
            document.documentElement.style.setProperty('--background-color-side-botton', tema.colors.colorBackgroundSideBoton);
            document.documentElement.style.setProperty('--secondary-custom', tema.colors.secondaryCustom);
            document.documentElement.style.setProperty('--varibale-blanca', tema.colors.variableBlanca);
            document.documentElement.style.setProperty('--blanco-gris', tema.colors.blancoGris);
            document.documentElement.style.setProperty('--widgetblock', tema.colors.widgetBlock);
            document.documentElement.style.setProperty('--widgetoffline', tema.colors.widgetOffLine);
            document.documentElement.style.setProperty('--widgetconfirmed', tema.colors.widgetConfirmed);
            document.documentElement.style.setProperty('--widgetrequested', tema.colors.widgetRequested);
            document.documentElement.style.setProperty('--widgetblock-dark', tema.colors.widgetBlockDark);
            document.documentElement.style.setProperty('--widgetoffline-dark', tema.colors.widgetOffLineDark);
            document.documentElement.style.setProperty('--widgetconfirmed-dark', tema.colors.widgetConfirmedDark);
            document.documentElement.style.setProperty('--widgetrequested-dark', tema.colors.widgetRequestedDark);
            document.documentElement.style.setProperty('--widgetdelivery_dashboard_1', tema.colors.widgetdelivery_dashboard_1);
            document.documentElement.style.setProperty('--widgetdelivery_dashboard_1-dark', tema.colors.widgetdelivery_dashboard_1Dark);
            document.documentElement.style.setProperty('--breadcrumb', tema.colors.breadcrumb);
            document.documentElement.style.setProperty('--iconotoggle', tema.colors.iconoToggle);
            document.documentElement.style.setProperty('--glow', tema.colors.glow);
            document.documentElement.style.setProperty('--badge-danger', tema.colors.badgeDanger);
            document.documentElement.style.setProperty('--background-color-side-log', tema.colors.colorBackgroundSideLogo);
            document.documentElement.style.setProperty('--confirmed-button', tema.colors.confirmedButton);
            document.documentElement.style.setProperty('--confirmed-button-dark', tema.colors.confirmedButtonDark);
            document.documentElement.style.setProperty('--release-button', tema.colors.releaseButton);
            document.documentElement.style.setProperty('--release-button-dark', tema.colors.releaseButtonDark);
            document.documentElement.style.setProperty('--cancel-button-dark', tema.colors.cancelButtonDark);
            document.documentElement.style.setProperty('--cancel-button', tema.colors.cancelButton);
            document.documentElement.style.setProperty('--complete-button-dark', tema.colors.completeButtonDark);
            document.documentElement.style.setProperty('--complete-button', tema.colors.completeButton);
            document.documentElement.style.setProperty('--scheduler-status-0', tema.colors.schedulerStatus0);
            document.documentElement.style.setProperty('--scheduler-status-1', tema.colors.schedulerStatus1);
            document.documentElement.style.setProperty('--scheduler-status-2', tema.colors.schedulerStatus2);
            document.documentElement.style.setProperty('--scheduler-status-3', tema.colors.schedulerStatus3);
            document.documentElement.style.setProperty('--scheduler-status-4', tema.colors.schedulerStatus4);
            document.documentElement.style.setProperty('--scheduler-status-5', tema.colors.schedulerStatus5);
            document.documentElement.style.setProperty('--scheduler-status-6', tema.colors.schedulerStatus6);
            document.documentElement.style.setProperty('--scheduler-status-7', tema.colors.schedulerStatus7);
            document.documentElement.style.setProperty('--scheduler-status-8', tema.colors.schedulerStatus8);
        }
        if (tema.name == "laFargeHolcimblueAlternative" && tema.state == true) {
            document.documentElement.style.removeProperty('--primary');
            document.documentElement.style.setProperty('--primary', tema.colors.primary);
            document.documentElement.style.setProperty('--primary-dark', tema.colors.primaryDark);
            document.documentElement.style.setProperty('--primary-50', tema.colors.primary50);
            document.documentElement.style.setProperty('--primary-dark-theme', tema.colors.primaryDarkTheme);
            document.documentElement.style.setProperty('--primary-dark-theme-dark', tema.colors.primaryDarkThemeDark);
            document.documentElement.style.setProperty('--font-color-header', tema.colors.fontColorHeader);
            document.documentElement.style.setProperty('--font-color-card-header', tema.colors.fontColorCardHeader);
            document.documentElement.style.setProperty('--font-color-card-header-accordeon', tema.colors.fontColorCardHeaderAccordeon);
            document.documentElement.style.setProperty('--color-background-header', tema.colors.colorBackgroundHeader);
            document.documentElement.style.setProperty('--icon-color', tema.colors.iconColor);
            document.documentElement.style.setProperty('--link-color', tema.colors.linkColor);
            document.documentElement.style.setProperty('--font-color-select', tema.colors.fontColorSelect);
            document.documentElement.style.setProperty('--background-color-side', tema.colors.colorBackgroundSide);
            document.documentElement.style.setProperty('--background-color-side-toggle', tema.colors.colorBackgroundSideToggle);
            document.documentElement.style.setProperty('--background-color-side-hover', tema.colors.colorBackgroundSideHover);
            document.documentElement.style.setProperty('--background-color-side-botton', tema.colors.colorBackgroundSideBoton);
            document.documentElement.style.setProperty('--secondary-custom', tema.colors.secondaryCustom);
            document.documentElement.style.setProperty('--varibale-blanca', tema.colors.variableBlanca);
            document.documentElement.style.setProperty('--blanco-gris', tema.colors.blancoGris);
            document.documentElement.style.setProperty('--widgetblock', tema.colors.widgetBlock);
            document.documentElement.style.setProperty('--widgetoffline', tema.colors.widgetOffLine);
            document.documentElement.style.setProperty('--widgetconfirmed', tema.colors.widgetConfirmed);
            document.documentElement.style.setProperty('--widgetrequested', tema.colors.widgetRequested);
            document.documentElement.style.setProperty('--widgetblock-dark', tema.colors.widgetBlockDark);
            document.documentElement.style.setProperty('--widgetoffline-dark', tema.colors.widgetOffLineDark);
            document.documentElement.style.setProperty('--widgetconfirmed-dark', tema.colors.widgetConfirmedDark);
            document.documentElement.style.setProperty('--widgetrequested-dark', tema.colors.widgetRequestedDark);
            document.documentElement.style.setProperty('--widgetdelivery_dashboard_1', tema.colors.widgetdelivery_dashboard_1);
            document.documentElement.style.setProperty('--widgetdelivery_dashboard_1-dark', tema.colors.widgetdelivery_dashboard_1Dark);
            document.documentElement.style.setProperty('--breadcrumb', tema.colors.breadcrumb);
            document.documentElement.style.setProperty('--iconotoggle', tema.colors.iconoToggle);
            document.documentElement.style.setProperty('--glow', tema.colors.glow);
            document.documentElement.style.setProperty('--badge-danger', tema.colors.badgeDanger);
            document.documentElement.style.setProperty('--background-color-side-log', tema.colors.colorBackgroundSideLogo);
            document.documentElement.style.setProperty('--confirmed-button', tema.colors.confirmedButton);
            document.documentElement.style.setProperty('--confirmed-button-dark', tema.colors.confirmedButtonDark);
            document.documentElement.style.setProperty('--release-button', tema.colors.releaseButton);
            document.documentElement.style.setProperty('--release-button-dark', tema.colors.releaseButtonDark);
            document.documentElement.style.setProperty('--cancel-button-dark', tema.colors.cancelButtonDark);
            document.documentElement.style.setProperty('--cancel-button', tema.colors.cancelButton);
            document.documentElement.style.setProperty('--complete-button-dark', tema.colors.completeButtonDark);
            document.documentElement.style.setProperty('--complete-button', tema.colors.completeButton);
            document.documentElement.style.setProperty('--scheduler-status-0', tema.colors.schedulerStatus0);
            document.documentElement.style.setProperty('--scheduler-status-1', tema.colors.schedulerStatus1);
            document.documentElement.style.setProperty('--scheduler-status-2', tema.colors.schedulerStatus2);
            document.documentElement.style.setProperty('--scheduler-status-3', tema.colors.schedulerStatus3);
            document.documentElement.style.setProperty('--scheduler-status-4', tema.colors.schedulerStatus4);
            document.documentElement.style.setProperty('--scheduler-status-5', tema.colors.schedulerStatus5);
            document.documentElement.style.setProperty('--scheduler-status-6', tema.colors.schedulerStatus6);
            document.documentElement.style.setProperty('--scheduler-status-7', tema.colors.schedulerStatus7);
            document.documentElement.style.setProperty('--scheduler-status-8', tema.colors.schedulerStatus8);
        }

    })
}


selectTheme(types.theme);


// console.log(color)
export default { types, selectTheme };